import api from "@oj/api";

import { mapMutations } from "vuex";

const mixins = {
  async mounted() {
    if (this.$route.params["board_id"]) {
      this.index = this.$route.params["board_id"];
      await this.getBoard(this.$route.params["board_id"]);
      
      this.title = this.board.board.title;
      this.content = this.board.board.content;

      this.isNotice = this.board.board.isNotice;


      if (this.board.board.flag === 3) {
        this.checked = true;
      } else {
        this.checked = false;
      }

      if (
        !this.isAdminRole &&
        this.board.board.created_by.id !== this.user.profile.user.id
      ) {
        if (this.$route.params.contestID) {
          this.$router.push({
            path: `/enterprise/contest/${this.$route.params.board_name.toLowerCase()}/${
              this.$route.params.contestID
            }/qna`,
            query: { page: this.currentPage },
          });
        }else{
          this.$router.push({
            path: `/qna/${this.$route.params.board_name.toLowerCase()}`,
            query: { page: this.$route.query.page }
          });
        }
        
      }
    }
    // 수정글 작성시 해당 로그인 권한과 맞춰서 권한이 맞지 않을경우 수정이 불가능하게 한다
  },
  methods: {
    ...mapMutations(["SET_HTML"]),
    async write() {
  
      if (!this.isExistsTitle(this.title)) return;
      if (!this.isTitleTooLong(this.title)) return;
      if (!this.isExistsContent(this.content)) return;
      if (!this.isContentTooLong(this.content)) return;

      // //이제 contestId가 존재하면서 문제가 존재한다면 해당 내용도 추가해서 넣어야한다.

      const data = {
        title: this.title,
        content : this.content,
        created_by_id: this.user.profile.user.id,
        type: this.checked === true ? "secret" : "normal",
        company: this.$route.params.board_name.toUpperCase(),
        isNotice: this.isNotice,
        problem_id: this.$route?.query?.problemID,
        contest_id: this.$route?.params?.contestID
      }
      const result = await api.postBoard(data);
      if (this.$route.params.contestID) {
        this.$router.push({
          path: `/enterprise/contest/${this.$route.params.board_name.toLowerCase()}/${
            this.$route.params.contestID
          }/qna`
        });
      }else{
        this.$router.push({
          path: `/qna/${this.$route.params.board_name.toLowerCase()}`,
          query: { page: this.$route.query.page }
        });
      }
      
    },
    update() {
      if (!this.isExistsTitle(this.title)) return;
      if (!this.isTitleTooLong(this.title)) return;
      if (!this.isExistsContent(this.content)) return;
      if (!this.isContentTooLong(this.content)) return;
      // if (this.isValidTitleAndContent(this.title, this.content) === false) return;

      this.$Modal.confirm({
        content: "게시글 수정을 하시겠습니까?",
        onOk: async () => {
          if (
            this.isAdminRole ||
            this.board.board.created_by.id === this.user.profile.user.id
          ) {
            // this.content = this.$store.state.board.html;
            const test = await api.putBoard(
              this.title,
              this.content,
              this.$route.params["board_id"],
              this.checked === true ? "secret" : "normal",
              this.isNotice
            );

            if (this.$route.params.contestID) {
              this.$router.push({
                path: `/enterprise/contest/${this.$route.params.board_name.toLowerCase()}/${
                  this.$route.params.contestID
                }/qna/${this.$route.params["board_id"]}`,
                query: { page: this.currentPage },
              });
            }else{
              this.$router.push({
                path: `/qna/${this.$route.params.board_name.toLowerCase()}/${
                  this.$route.params["board_id"]
                }`,
                query: { page: this.$route.query.page }
              });
            }
            
          }
        },
        onCancel: () => {}
      });
    },
    // isValidTitleAndContent(title, content){
    //   return title.length > 0 && title.length < 100 && content.length > 0 && content.length < 1000000
    // },
    isExistsTitle(title) {
      if (!title.length) {
        this.$error("제목을 입력해주세요");
        return;
      }
      return true;
    },
    isTitleTooLong(title) {
      if (title.length >= 100) {
        this.$error("제목은 100자 이내로 입력해주세요");
        return;
      }
      return true;
    },
    isExistsContent(content) {
      if (!content.length) {
        this.$error("내용을 입력해주세요");
        return;
      }
      return true;
    },
    isContentTooLong(content) {
      if (content.length > 5000000) {
        this.$error("내용은 5M 이상 업로드 할 수 없습니다.");
        return;
      }
      return true;
    }
  }
};

export default mixins;
