import api from "@oj/api";
import { mapState, mapActions, mapGetters } from "vuex";
import time from "@/utils/time";
import BoardHighlight from "@/pages/oj/components/BoardHighlight";
import Comment from "../board/Comment";
import ChangeCommentText from "../../components/ChangeCommentText.vue"
const mixins = {
    components: { Comment, BoardHighlight,  ChangeCommentText},

    data() {
        const index = this.$route.params.board_id;
        return {
            type:'0',
            typeText:'선택',
            content :'',
            data: [],
            index: index,
            flag: false,
            commentIndex: "",
            input: "",
            comment: "",
            defaultOptions:{
                // hooks:{
                //     getEditorElements: this.getEditorElements
                // }
            }
        };
    },
    created(){

    },
     mounted() {
       
        this.getBoard(this.$route.params["board_id"]).then(li => {
            this.content = this.board['board'].content;
            const command = this.board['board'].process
            if (command == 1) {
                this.type = "warning";
                this.typeText = '처리중';
              }
              if (command == 2) {
                this.type = "success";
                this.typeText = '완료';
              }
              if (command == 0) {
                  this.type = "primary";
                  this.typeText = 'new';
              }
            
             
        });
        
        

        
        if (!this.isSameCompany()) {
            this.$router.push("/");
            this.$error("권한이 없습니다.");
        }

        if (!this.isPublicPost() && !this.isPermission()) {
            this.$router.push("/");
            this.$error("권한이 없습니다.");
        }
    },
    computed: {
        ...mapGetters(["isSuperAdmin", "isAdminRole", "isManager"]),
        ...mapState(["board", "user"])
    },

    methods: {
        ...mapActions(["getBoard"]),
        // getEditorElements(...e){
        //     console.log(e);
        // },
        toLocal(data) {
            const result = time.utcToLocal(data, "YYYY년 M월 D일 HH시 mm분");
            return result;
        },
        simpleToLocal(data) {
            const result = time.utcToLocal(data, "YYYY-MM-DD HH:mm");
            return result;
        },
        commentToLocal(data) {
            const result = time.utcToLocal(data, "(YY-MM-DD HH:mm)");
            return result;
        },
        deleteComment(id) {
            this.$Modal.confirm({
                content: "댓글을 삭제하시겠습니까?",
                onOk: async () => {
                    const hello = await api.deleteComment(id);
                    const reuslt = await this.getBoard(this.$route.params["board_id"]);
                },
                onCancel: () => { }
            });
        },
        chageflag(id, comment) {
            this.flag = !this.flag;
            this.commentIndex = id;
            
            this.comment = comment;
            // let str = comment.replace(/<br\s*\/?>/gm, "\n");
            // this.comment = str;
        },
        async cancle() {
            const result = await this.getBoard(this.$route.params["board_id"]);
            this.flag = false;
            this.comment = "";
            this.commentIndex = "";
        },
        async changeComment(comment_id) {
            if (!this.comment.trim().length) {
                this.$error("댓글을 입력해주세요");
                return;
            }
            // let str = this.comment.replace(/ /g, "\u00a0");
            // str = str.replace(/(?:\r\n|\r|\n)/g, "<br/>");
            let str = this.comment;
            this.$Modal.confirm({
                content: "댓글을 수정하시겠습니까?",
                onOk: async () => {
                    await api.putComment(comment_id, str);
                    const reuslt = await this.getBoard(this.$route.params["board_id"]);
                    this.flag = false;
                    this.comment = "";
                    this.commentIndex = "";
                },
                onCancel: () => { }
            });
        },
        secretBoard(flag) {
            if (this.isPermission()) {
                let type = "";
                if (flag === 3) type = "normal";
                else type = "secret";
                this.$Modal.confirm({
                    content: `해당 게시글을 ${type === "normal" ? "공개" : "비공개"
                        } 처리 하시겠습니까?`,
                    onOk: async () => {
                        await api.secretBoard(this.$route.params["board_id"], type);
                        const reuslt = await this.getBoard(this.$route.params["board_id"]);
                    },
                    onCancel: () => { }
                });
            } else {
                this.$error("권한이 없습니다.");
            }
        },
        changeAnnounce(isNotice) {
            if (this.isPermission()) {
                
                this.$Modal.confirm({
                    content: `해당 게시글을 ${isNotice ? "공지해제" : "공지"
                        } 처리 하시겠습니까?`,
                    onOk: async () => {
                        await api.changeAnnounce(this.$route.params["board_id"], !isNotice);
                        const reuslt = await this.getBoard(this.$route.params["board_id"]);
                    },
                    onCancel: () => { }
                });
            } else {
                this.$error("권한이 없습니다.");
            }
        },
        secretComment(id, flag) {
            let type = "";
            if (flag === 3) type = "normal";
            else type = "secret";
            this.$Modal.confirm({
                content: `해당 댓글을 ${type === "normal" ? "공개" : "비공개"
                    } 처리 하시겠습니까?`,
                onOk: async () => {
                    await api.secretComment(id, type);
                    const reuslt = await this.getBoard(this.$route.params["board_id"]);
                },
                onCancel: () => { }
            });
        },
        isPermission() {
            return (this.user && this.user.profile.user.id === this.board['board']['created_by'].id) || this.isAdminRole || this.isManager
        },
        isPublicPost() {
            return this.board.board.flag === 0;
        },
        isSameCompany() {
            //관리자 글을 못보는문제 해결
            if(this.board['board']['created_by'].admin_type === "Super Admin"){
                    return true
            }

            if(this.board['board']['created_by'].admin_type === 'Regular User'){
                return true
            }
            // 기존유저들 고려 코드

            return (this.user && this.user.profile.github === this.board['board']['created_by'].github) || this.isAdminRole || this.isManager || (this.user.profile.user.admin_type === this.board['board']['created_by'].admin_type)
        },

        setType(process) {
            if (process == 0) return "primary";
            if (process == 1) return "warning";
            if (process == 2) return "success";
          },
      
          setTypeText(process) {
            if (process == 0) return "new";
            if (process == 1) return "처리중";
            if (process == 2) return "완료";
          },
      
          handleSetType(command) {
            
            if (command == 1) {
              this.type = "warning";
              this.typeText = '처리중';
            }
            if (command == 2) {
              this.type = "success";
              this.typeText = '완료';
            }
            if (command == 0) {
                this.type = "primary";
                this.typeText = 'new';
            }

            // 이제 해당 진행하고 나서 api 호출로 상태값 변경시키기
            api.changeProcess(this.$route.params.board_id , command)
          },
          setComment(e) {
        
            this.comment = e;
          },
    }
}


export default mixins;