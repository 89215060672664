<template>
  <Panel shadow :padding="10">
    <div slot="title">
      {{ title }}
    </div>
    <div slot="extra">
      <Button v-if="isAdminRole && !isNews" type="info" @click="moveTutorCode"
        >강사님자료 작성/수정</Button
      >
    </div>
    <transition-group name="announcement-animate" mode="in-out">
      <template v-if="listVisible">
        <Table
          class="lecture-announce"
          @on-row-click="move"
          :columns="columns"
          :data="announcements"
          key="list"
        ></Table>
      </template>

      <template v-else>
        <BoardHighlight
          v-katex
          :code="announcement.content"
          key="content"
          class="content-container markdown-body"
        ></BoardHighlight>
      </template>
    </transition-group>
  </Panel>
</template>

<script>
import moment from "moment";

import api from "@oj/api";
import Pagination from "@oj/components/Pagination";
import { mapGetters, mapActions, mapState } from "vuex";
import BoardHighlight from "@/pages/oj/components/BoardHighlight";

export default {
  name: "Announcement",
  components: {
    Pagination,

    BoardHighlight,
  },
  data() {
    return {
      showEditAnnouncementDialog: false,
      limit: 10,
      total: 10,
      btnLoading: false,
      currentAnnouncementId: null,
      columns: [
        {
          title: "글 번호",
          width: "14%",
          align: "center",
          render: (h, params) => {
            return h("span", params.row.id);
          },
        },
        {
          title: "제목",
          width: "58%",
          align: "left",
          resizable: true,
          render: (h, params) => {
            
            return h("span", params.row.title, {
              
            });
          },
        },
        {
          title: "날짜",
          width: "30%",
          align: "center",
          render: (h, params) => {
            

            
            return h("div", 
            
            moment(params.row.created_time).format("YYYY-MM-DD") + "\n" +  moment(params.row.created_time).format("HH:mm:ss"),
        
            );
          },
        },
        // {
        //   title: "작성자",
        //   width: "14%",
        //   align: "center",
        //   render: (h, params) => {
        //     return h("span", params.row.created_by["real_name"], {
        //       style:{
        //         "padding": 0,
        //         "min-width":"30px"
        //       }
        //     });
        //   },
        // },
      ],
      tutor: {
        title: "",
        visible: true,
        content: "",
      },
      announcements: [],
      announcement: "",
      listVisible: true,
      isNews: true,
    };
  },
  mounted() {
    this.init();
    this.getProfile();
  },
  methods: {
    ...mapActions(["getProfile", "changeModalStatus"]),
    init() {
      if (this.isContest) {
        this.getContestAnnouncementList();
        this.isNews = false;
      } else {
        this.getAnnouncementList();
      }
    },
    getAnnouncementList(page = 1) {
      this.btnLoading = true;
      api.getNoticeList({
        limit: 10,
        offset: (this.currentPage - 1) * 10,
        keyword: this.keyword,
        company: "MIRACOM"
      }).then(
        (res) => {
          this.btnLoading = false;
          this.announcements = res.data.data.results;
          this.total = res.data.data.total;
        },
        () => {
          this.btnLoading = false;
        }
      );
    },
    getContestAnnouncementList() {
      this.btnLoading = true;
      api.getNoticeList(
        {
        limit: 10,
        offset: (this.currentPage - 1) * 10,
        keyword: this.keyword,
        company: "MIRACOM"
      }
      ).then(
        (res) => {
          this.btnLoading = false;
          this.announcements = res.data.data;
        },
        () => {
          this.btnLoading = false;
        }
      );
    },
    goAnnouncement(announcement) {
      this.announcement = announcement;
      this.listVisible = false;
    },
    goBack() {
      this.listVisible = true;
      this.announcement = "";
    },
    moveTutorCode() {
      window.open(
        `/admin/contest/${this.$route.params.contestID}/announcement`
      );
    },
    move(row, index) {
      this.$router.push(`/announcement/miracom/${row.id}`)
      // this.goAnnouncement(row);
    },
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["user", "isAuthenticated", "isAdminRole", "isAdminRole"]),
    title() {
      if (this.listVisible) {
        return this.isContest
          ? "강사님 자료"
          : this.$i18n.t("m.News");
      } else {
        return this.announcement.title;
      }
    },
    isContest() {
      return !!this.$route.params.contestID;
    },
  },
};
</script>

<style lang="less">
.ivu-table-cell{
  padding:0 !important; 
}
.announcements-container {
  margin-top: -10px;
  margin-bottom: 10px;
  li {
    padding-top: 15px;
    list-style: none;
    padding-bottom: 15px;
    margin-left: 20px;
    font-size: 16px;
    border-bottom: 1px solid rgba(187, 187, 187, 0.5);
    &:last-child {
      border-bottom: none;
    }
    .flex-container {
      .title {
        flex: 1 1;
        text-align: left;
        padding-left: 10px;
        a.entry {
          color: #495060;
          &:hover {
            color: #2d8cf0;
            border-bottom: 1px solid #2d8cf0;
          }
        }
      }
      .creator {
        flex: none;
        width: 200px;
        text-align: center;
      }
      .date {
        flex: none;
        width: 200px;
        text-align: center;
      }
    }
  }
}

.content-container {
  padding: 0 20px 20px 20px;
}

.no-announcement {
  text-align: center;
  font-size: 16px;
}
changeLocale .announcement-animate-enter-active {
  animation: fadeIn 1s;
}
.lecture-announce .ivu-table-header table th {
  text-align: center !important;
}
</style>
