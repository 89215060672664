<template>
  <Row type="flex" :gutter="18">
    <Col :span="24">
      <Panel shadow>
        <!-- edit 버튼 추가 (2개, 설명수정, 리스트 수정) -->
        <div v-if="problemList.title" slot="title">
          {{ problemList.title }} : {{ problemList.content }}
        </div>
        <!-- adminRole이 존재할 때  -->
        <div style="padding-left: 20px" v-if="isAdminRole">
          <div>
            <a @click="levelEdit">Level 수정하기</a>
          </div>
          <div>
            <a @click="detailListEdit">문제 리스트 추가하기</a>
          </div>
        </div>

        <div v-else slot="title">문제 목록</div>
        <!-- edit 버튼 추가  -->

          <BoardHighlight class="bg-code ck-content"  :code="problemList.content_detail"></BoardHighlight>

        <div class="problem-list-table-wrapper">
            <Table
          border
          resizable="true"
          style="font-size: 16px;    margin-top: 20px;"
          width="auto"
          :columns="problemTableColumns"
          :data="problemList['problem_step_level']"
          :loading="loadings.table"
          data-test-id="table-problem-detail-list"
        >
        </Table>
        </div>
      </Panel>
    </Col>
  </Row>
  <!--
  @on-row-click="move"
  -->
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import api from "@oj/api";
import utils from "@/utils/utils";

import BoardHighlight from "../../components/BoardHighlight.vue";
export default {
    mixins: [],
    name: "ProblemStepLevelDetailList",
    data() {
        return {
            tagList: [],
            problemTableColumns: [
                {
                    title: "해결",
                    key: "_id",
                    width: this.$store.state.isMobile ? 40 : 50,
                    align: "center",
                    render: (h, params) => {
                        let solveAcmProblem;
                        let solveOiProblem;
                        let status = null;
                        if (this.$store.state.user.profile.acm_problems_status.problems) {
                            solveAcmProblem =
                                this.$store.state.user.profile.acm_problems_status.problems[params.row.problem.id];
                        }
                        if (this.$store.state.user.profile.oi_problems_status.problems) {
                            solveOiProblem =
                                this.$store.state.user.profile.oi_problems_status.problems[[params.row.problem.id]];
                        }
                        if (solveAcmProblem) {
                            status = solveAcmProblem.status;
                        }
                        if (solveOiProblem) {
                            status = solveOiProblem.status;
                        }
                        if (status === null || status === undefined) {
                            return undefined;
                        }
                        return h("Icon", {
                            props: {
                                type: status === 0 ? "checkmark-round" : "minus-round",
                                size: "16",
                            },
                            style: {
                                color: status === 0 ? "#19be6b" : "#ed3f14",
                            },
                        });
                    },
                },
                {
                    title: "문제 번호",
                    key: "_id",
                    width: this.$store.state.isMobile ? 60 : 100,
                    align: "center",
                    render: (h, params) => {
                        return h("Button", {
                            props: {
                                type: "text",
                                size: "small",
                            },
                        }, `${params.row._index + 1} 번`);
                    },
                },
                {
                    title: this.$i18n.t("m.Title"),
                    className: "iview-table-title",
                    align: "left",
                    width: this.$store.state.isMobile ? 350 : 400,
                    style: {
                        
                        // width: "600px",
                    },
                    render: (h, params) => {
                        return h('div',[
                            h("Button", {
                                on: {
                                    click: (e) => {
                                        this.move(params.row);
                                    },
                                },
                                props: {
                                    type: "text",
                                    size: "large",
                                },
                                style: {
                                    "padding-left": "30px",
                                    "padding-right": "10px",
                                },
                                // TODO 여기에 뭐 붙이기
                            }, params.row.title),
                            ((params.row.problem?.main_code_visible && params.row.problem?.main_code) )&& h("Tag", {
    
                            // TODO 여기에 뭐 붙이기
                            }, "C++"),
                            ((params.row.problem?.main_code_visible &&  params.row.problem?.java_main_code)) && h("Tag", {
    
                            // TODO 여기에 뭐 붙이기
                            }, "Java"),
                            ((params.row.problem?.main_code_visible && params.row.problem?.python_main_code ))&& h("Tag", {
                            
                            // TODO 여기에 뭐 붙이기
                            }, "Python"),
                        ]);
                    },
                },
                {
                    title: "난이도",
                    width: this.$store.state.isMobile ? 100 : 150,
                    align: "center",
                    render: (h, params) => {
                        let t = params.row.difficulty;
                        let color = "blue";
                        if (t === 1)
                            color = "green";
                        else if (t === 3)
                            color = "yellow";
                        let text = "Mid";
                        if (t === 1)
                            text = "Low";
                        if (t === 3)
                            text = "High";
                        return h("tag", {
                            props: {
                                color: color,
                            },
                        }, text);
                    },
                },
                {
                    title: "TC",
                    align: "center",
                    width: 100,
                    render: (h, params) => {
                        return h("span", `${params.row.problem.test_case_score.length} 개`);
                    },
                },
                // {
                //     title: "해설",
                //     key: "submission_number",
                //     align: "center",
                //     width: this.$store.state.isMobile ? 60 : 100,
                //     render: (h, params) => {
                //         return h("span", params.row.problem.source ? "✔️" : "❌");
                //     },
                // },
                {
                    title: "즐겨찾기",
                    width: this.$store.state.isMobile ? 60 : 150,
                    align: "center",
                    render: (h, params) => {
                        const checked = this.favoriteList.includes(params.row.problem.id);
                        return h("Checkbox", {
                            on: {
                                "on-change": e => {
                                    const problem_id = params.row.problem.id;
                                    const user_id = this.$store.state["user"]["profile"].id;
                                    const data = { user_id, problem_id };
                                    if (e) {
                                        this.addFavorite(data);
                                    }
                                    else {
                                        this.removeFavorite(data);
                                    }
                                }
                            },
                            props: {
                                value: checked
                            }
                        });
                    }
                }
            ],
            problemList: [],
            limit: 20,
            total: 0,
            loadings: {
                table: true,
                tag: true,
            },
            routeName: "",
            query: {
                keyword: "",
                difficulty: "",
                tag: "",
                page: 1,
            },
            favoriteList: [],
        };
    },
    mounted() {
        if (!this.isLoggedIn()) {
            this.$router.push("/");
            this.$error("권한이 없습니다.");
        }
        this.init();
        this.getProfile();
    },
    methods: {
        ...mapActions(["getProfile"]),
        async init(simulate = false) {
            this.routeName = this.$route.name;
            let query = this.$route.query;
            this.query.difficulty = query.difficulty || "";
            this.query.keyword = query.keyword || "";
            this.query.tag = query.tag || "";
            this.query.page = parseInt(query.page) || 1;
            if (this.query.page < 1) {
                this.query.page = 1;
            }
            if (!simulate) {
                this.getTagList();
            }
            this.getProblemList();
            this.favoriteList = await this.getList();
        },
        pushRouter() {
            this.$router.push({
                name: "problem-list",
                query: utils.filterEmptyValue(this.query),
            });
        },
        async getProblemList() {
            this.loadings.table = true;
            const { level_id } = this.$route.params;
            const { data } = await api.getStepLevel(level_id);
            this.problemList = data["data"]["data"];
            this.loadings.table = false;
        },
        getTagList() {
            api.getProblemTagList().then((res) => {
                this.tagList = res.data.data;
                this.loadings.tag = false;
            }, (res) => {
                this.loadings.tag = false;
            });
        },
        filterByTag(tagName) {
            this.query.tag = tagName;
            this.query.page = 1;
            this.pushRouter();
        },
        filterByDifficulty(difficulty) {
            this.query.difficulty = difficulty;
            this.query.page = 1;
            this.pushRouter();
        },
        filterByKeyword() {
            this.query.page = 1;
            this.pushRouter();
        },
        handleTagsVisible(value) {
            if (value) {
                this.problemTableColumns.push({
                    title: this.$i18n.t("m.Tags"),
                    align: "center",
                    render: (h, params) => {
                        let tags = [];
                        params.row.tags.forEach((tag) => {
                            tags.push(h("Tag", {}, tag));
                        });
                        return h("div", {
                            style: {
                                margin: "8px 0",
                            },
                        }, tags);
                    },
                });
            }
            else {
                this.problemTableColumns.splice(this.problemTableColumns.length - 1, 1);
            }
        },
        onReset() {
            this.$router.push({ name: "problem-list" });
        },
        levelEdit() {
            const { level_id, step_id } = this.$route.params;
            window.open(`/admin/problem-steps/${step_id}/level/${level_id}/edit`);
        },
        detailListEdit() {
            const { level_id, step_id } = this.$route.params;
            window.open(`/admin/problem-steps/${step_id}/level/${level_id}/detail`);
        },
        async getList() {
            const { data } = await api.getFavorite(this.$store.state.user.profile.id);
            const favoriteArray = data.data.map(e => e.problem.id);
            return favoriteArray;
        },
        move(row, index) {
            // console.log(`${this.$route.fullPath}/${row.problem._id}`);
            this.$router.push(`${this.$route.fullPath}/${row.problem._id}`);
            // this.$router.push(`/problem/${row.problem._id}`);
            // 라우터 변경하기
        },
        async addFavorite(data) {
            const { result } = await api.postFavorite(data);
        },
        async removeFavorite(data) {
            const { result } = await api.deleteFavorite(data);
        },
        isLoggedIn() {
            return !!(this.$store.state["user"]["profile"] &&
                this.$store.state["user"]["profile"].id);
        },
    },
    computed: {
        ...mapGetters(["isAuthenticated", "isAdminRole", "isSuperAdmin"]),
        ...mapState(["utils", "user"]),
        isMixed: function(){
            return this.problemList['problem_step_level']?.some(li => li.problem.main_code_visible)
        }
    },
    watch: {
        $route(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.init(true);
            }
        },
        isAuthenticated(newVal) {
            if (newVal === true) {
                this.init();
            }
        },
    },
    components: { BoardHighlight }
};
</script>

<style lang="less" scoped>
    
</style>
<style lang="less">
.iview-table-title .ivu-table-cell {
  padding: 0;
}
.problem-list-table-wrapper .ivu-table table{
        min-width: auto !important;
        width: max-content !important;
     
}
@media screen and (min-width: 800px) {
    .problem-list-table-wrapper .ivu-table table{
        width:100% !important;
    }
}

</style>
