export const m = {
    // 404.vue
  Go_Home: '返回主页',
  // Problem.vue
  Description: '题目描述',
  Input: '输入',
  Output: '输出',
  Sample_Input: '输入样例',
  Sample_Output: '输出样例',
  Hint: '提示',
  Source: '题目来源',
  Status: '状态',
  Information: '题目信息',
  Time_Limit: '时间限制',
  Memory_Limit: '内存限制',
  Created: '出题人',
  Level: '难度',
  Score: '分数',
  Tags: '标签',
  Show: '显示',
  Submit: '提交',
  Submitting: '正在提交',
  Judging: '正在评分',
  Wrong_Answer: '答案错误',
  Statistic: '统计',
  Close: '关闭',
  View_Contest: '查看比赛',
  Are_you_sure_you_want_to_reset_your_code: '确定要重置代码吗？',
  Code_can_not_be_empty: '不能提交空代码',
  Submit_code_successfully: '成功提交代码',
  You_have_solved_the_problem: '你已经解决了该问题',
  Submitted_successfully: '成功提交',
  You_have_submitted_a_solution: '你已经提交了解答',
  Contest_has_ended: '比赛已结束',
  You_have_submission_in_this_problem_sure_to_cover_it: '你已经提交了解答，确定要覆盖吗？',
  // About.vue
  Compiler: '编译器',
  Result_Explanation: '结果解释',
  Pending_Judging_Description: '您的解答将很快被测评，请等待结果。',
  Compile_Error_Description: '无法编译您的源代码，点击链接查看编译器的输出。',
  Accepted_Description: '你的解题方法是正确的。',
  Wrong_Answer_Description: '你的程序输出结果与判题程序的答案不符。',
  Runtime_Error_Description: '您的程序异常终止，可能的原因是：段错误，被零除或用非0的代码退出程序。',
  Time_Limit_Exceeded_Description: '您的程序使用的 CPU 时间已超出限制。',
  Memory_Limit_Exceeded_Description: '程序实际使用的内存已超出限制。',
  System_Error_Description: '糟糕，判题程序出了问题。请报告给管理员。',
  // ACMContestRank.vue
  Menu: '菜单',
  Chart: '图表',
  Auto_Refresh: '自动刷新',
  RealName: '真名',
  Force_Update: '强制刷新',
  download_csv: '下载 csv',
  TotalTime: '总时间',
  Top_10_Teams: '前 10 强队伍',
  save_as_image: '保存图片',
  // ACMHelper.vue
  ACM_Helper: 'ACM 助手',
  AC_Time: 'AC 时间',
  ProblemID: '问题 ID',
  First_Blood: '一血',
  Username: '用户名',
  Checked: '已检查',
  Not_Checked: '未检查',
  Check_It: '现在检查',
  // ACMRank.vue
  ACM_Ranklist: 'ACM 排名',
  mood: '格言',
  AC: 'AC',
  Rating: '评分',
  // Announcements.vue
  Contest_Announcements: '比赛公告',
  By: '创建人',
  // ApplyResetPassword.vue
  The_email_doesnt_exist: '该电子邮件地址不存在',
  Success: '成功',
  Password_reset_mail_sent: '密码重置邮件已发送。',
  // FAQ.vue
  Frequently_Asked_Questions: '常见问题',
  Where_is_the_input_and_the_output: '输入和输出在哪里？',
  Where_is_the_input_and_the_output_answer_part_1: '您的程序应从',
  Standard_Input: '标准输入',
  Where_is_the_input_and_the_output_answer_part_3: '读取输入，并将输出写入',
  Standard_Output: '标准输出',
  Where_is_the_input_and_the_output_answer_part_5: '例如，您可以在 C 中使用',
  Where_is_the_input_and_the_output_answer_part_6: '或在 C ++ 中使用',
  Where_is_the_input_and_the_output_answer_part_7: '读取，并在 C 中使用',
  Where_is_the_input_and_the_output_answer_part_8: '或在 C ++ 中使用',
  Where_is_the_input_and_the_output_answer_part_9: '写入 stdout。用户程序不允许读取或写入文件，否则您将收到',
  What_is_the_meaning_of_submission_execution_time: '提交执行时间是什么意思？',
  What_is_the_meaning_of_submission_execution_time_answer: 'OnlineJudge 可能会使用不同的输入文件多次测试您的代码。如果您的代码在每个输入文件的时间限制内给出正确的答案，则显示的执行时间是每个测试用例所花费的最大时间。否则，执行时间将毫无意义。',
  How_Can_I_use_CPP_Int64: '如何使用 C ++ Int64？',
  How_Can_I_use_CPP_Int64_answer_part_1: '您应该声明',
  How_Can_I_use_CPP_Int64_answer_part_2: '并与',
  or: '或',
  using: '一起使用，使用',
  How_Can_I_use_CPP_Int64_answer_part_3: ' 将导致',
  Java_specifications: 'Java 规范？',
  Java_specifications_answer_part_1: '所有程序都必须以',
  Java_specifications_answer_part_2: '类的静态 main 方法开始。不要使用公共类：即使',
  Java_specifications_answer_part_3: '也必须是非公共类以避免编译错误使用缓冲I / O以避免由于过度刷新而导致超出时间限制',
  About_presentation_error: '关于输出格式错误？',
  About_presentation_error_answer_part_1: '该 OJ 中没有输出格式错误。Judger将对自动对输出修整然后包裹在输出的',
  last: '最后',
  About_presentation_error_answer_part_2: '一行中。如果仍然与正确的输出不同，则结果将是',
  How_to_report_bugs: '如何报告有关此 OJ 的错误？',
  How_to_report_bugs_answer_part_1: 'onlinejudge 是开源的，您可以到',
  How_to_report_bugs_answer_part_2: '提交问题。需要有关错误的详细信息（例如env，版本..），这将帮助我们极大地解决该错误。当然，我们很高兴合并您的请求。',
  // Cancel.vue
  Cancel: '取消',
  // ContestDetail.vue
  Problems: '题目',
  Announcements: '公告',
  Submissions: '提交信息',
  Rankings: '排名',
  Overview: '概要',
  Admin_Helper: '管理员助手',
  StartAt: '开始时间',
  EndAt: '结束时间',
  ContestType: '比赛类型',
  Creator: '发起人',
  Public: '公开',
  Password_Protected: '密码保护',
  // ContestList.vue
  Rule: '赛制',
  OI: 'OI',
  ACM: 'ACM',
  Underway: '进行中',
  Not_Started: '筹备中',
  Ended: '已结束',
  No_contest: '尚无练习或比赛',
  Please_login_first: '请先登录！',
  // ContestProblemList.vue
  Problems_List: '问题列表',
  No_Problems: '尚无问题',
  // CodeMirror.vue
  Language: '语言',
  Theme: '主题',
  Reset_to_default_code_definition: '重设返回默认代码设置',
  Upload_file: '上传文件',
  Monokai: '物界',
  Solarized_Light: '日光灯',
  Material: '材料',
  // KatexEditor.vue
  Latex_Editor: 'Latex 编辑器',
  // NavBar.vue
  Home: '首页',
  NavProblems: '问题',
  Contests: '练习&比赛',
  NavStatus: '状态',
  Rank: '排名',
  ACM_Rank: 'ACM 排名',
  OI_Rank: 'OI 排名',
  About: '关于',
  Judger: '评分器',
  FAQ: '소스코드 제출방법',
  Login: '登录',
  Register: '注册',
  MyHome: '我的主页',
  MySubmissions: '我的提交',
  Settings: '我的设置',
  Management: '后台管理',
  Logout: '退出',
  Welcome_to: '欢迎来到',
  // announcements.vue
  Refresh: '刷新',
  Back: '返回',
  No_Announcements: '暂无公告',
  // Setting.vue
  Profile: '个人信息设置',
  Account: '账号设置',
  Security: '安全设置',
  // AccoutSetting.vue
  ChangePassword: '更改密码',
  ChangeEmail: '更改邮箱',
  Update_Password: '更新密码',
  // ProfileSetting.vue
  Avatar_Setting: '头像设置',
  Profile_Setting: '个人信息设置',
  // SecuritySettig
  Sessions: '登录记录',
  Two_Factor_Authentication: '双因素认证',
  // Login.vue
  LoginUsername: '用户名',
  LoginPassword: '密码',
  TFA_Code: 'TFA App 双因素认证码',
  No_Account: '还没账号，立即注册!',
  Forget_Password: '忘记密码',
  UserLogin: '登录',
  Welcome_back: '欢迎回来',
  // OIRank.vue
  OI_Ranklist: 'OI 排名',
  // OIContestRank.vue
  Total_Score: '总分',
  // ProblemList.vue
  Problem_List: '问题列表',
  High: '高',
  Mid: '中',
  Low: '低',
  All: '全部',
  Reset: '重置',
  Pick_One: '选择',
  Difficulty: '难度',
  Total: '总数',
  AC_Rate: '通过率',
  // Register.vue
  RegisterUsername: '用户名',
  Email_Address: '电子邮箱',
  RegisterPassword: '密码',
  Password_Again: '确认密码',
  Captcha: '验证码',
  UserRegister: '注册',
  Already_Registed: '已经注册？现在登录!',
  The_username_already_exists: '该用户名已存在',
  The_email_already_exists: '该电子邮件地址已存在',
  password_does_not_match: '密码不匹配',
  Thanks_for_registering: '感谢注册，您现在可以登录了',
  // ResetPassword.vue and ApplyResetPassword.vue
  Reset_Password: '重置密码',
  RPassword: '密码',
  RPassword_Again: '确认密码',
  RCaptcha: '验证码',
  ApplyEmail: '电子邮箱',
  Send_Password_Reset_Email: '发送重置密码到邮箱',
  Your_password_has_been_reset: '您的密码已重置',
  // Save.vue
  Save: '保存',

  Uploading_is_in_progress: '正在上传，您确定要离开当前页面吗？',
  // SubmissionDetails.vue
  Lang: '语言',
  Share: '分享',
  UnShare: '不分享',
  Succeeded: '成功',
  Real_Time: '真实时间',
  Signal: '信号',
  // SubmissionList.vue
  When: '时间',
  ID: 'ID',
  Time: '时间',
  Memory: '内存',
  Author: '作者',
  Option: '选项',
  Mine: '我的',
  Search_Author: '搜索作者',
  Accepted: '答案正确',
  Time_Limit_Exceeded: '运行超时',
  Memory_Limit_Exceeded: '内存超限',
  Runtime_Error: '运行时错误',
  System_Error: '系统错误',
  Pending: '等待评分',
  Partial_Accepted: '部分正确',
  Compile_Error: '编译失败',
  Rejudge: '重新评分',
  // UserHome.vue
  UserHomeSolved: '已解决问题的数量',
  UserHomeserSubmissions: '提交次数',
  UserHomeScore: '分数',
  List_Solved_Problems: '已解决问题的列表',
  UserHomeIntro: '这个家伙太懒了，还没有做题呢...'
}
