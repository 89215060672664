export const m = {
  // 404.vue
  Go_Home: 'Go Home',
  // Problem.vue
  Description: '설명',
  Input: '입력',
  Output: '출력',
  Sample_Input: '입력 예시',
  Sample_Output: '출력 예시',
  Hint: 'Hint',
  Source: '소스코드',
  Status: 'Status',
  Information: 'Information',
  Time_Limit: 'Time Limit',
  Memory_Limit: 'Memory Limit',
  Created: 'Created By',
  Level: 'Level',
  Score: 'Score',
  Tags: 'Tags',
  Show: 'Show',
  Submit: '제출하기',
  Submitting: '전송중...',
  Judging: '채점중...',
  Wrong_Answer: '오답',
  Statistic: 'Statistic',
  Close: 'Close',
  View_Contest: 'View Contest',
  Are_you_sure_you_want_to_reset_your_code: 'Are you sure you want to reset your code?',
  Code_can_not_be_empty: 'Code can not be empty',
  Submit_code_successfully: 'Submit code successfully',
  You_have_solved_the_problem: 'You have solved the problem',
  Submitted_successfully: 'Submitted successfully',
  You_have_submitted_a_solution: 'You have submitted a solution.',
  Contest_has_ended: 'Contest has ended',
  You_have_submission_in_this_problem_sure_to_cover_it: 'You have submission in this problem, sure to cover it?',
  // About.vue
  Compiler: 'Compiler',
  Result_Explanation: '결과 설명',
  Pending_Judging_Description: '곧 채점 될 예정입니다. 잠시만 기다려주세요.',
  Compile_Error_Description: '소스코드 컴파일에 실패했습니다. 컴파일 오류 내용을 확인하세요.',
  Accepted_Description: 'Congratulations. Your solution is correct.',
  Wrong_Answer_Description: "Your program's output doesn't match judger's answer.",
  Runtime_Error_Description: '프로그램이 비정상적으로 종료되었습니다. segment fault, divided by zero 와 같은 에러가 있는지 확인 해주세요.',
  Time_Limit_Exceeded_Description: '시간초과 되었습니다.',
  Memory_Limit_Exceeded_Description: '과도한 변수 or 배열 사용으로 메모리가 초과되었습니다.',
  System_Error_Description: '채점 시스템에 오류가 발생했습니다. 관리자에게 문의하세요.',
  // ACMContestRank.vue
  Menu: 'Menu',
  Chart: 'Chart',
  Auto_Refresh: 'Auto Refresh',
  RealName: 'RealName',
  Force_Update: 'Force Update',
  download_csv: 'download csv',
  TotalTime: 'TotalTime',
  Top_10_Teams: 'Top 10 Teams',
  save_as_image: 'save as image',
  // ACMHelper.vue
  ACM_Helper: 'ACM Helper',
  AC_Time: 'AC Time',
  ProblemID: 'ProblemID',
  First_Blood: 'First Blood',
  Username: 'Username',
  Checked: 'Checked',
  Not_Checked: 'Not Checked',
  Check_It: 'Check It',
  // ACMRank.vue
  ACM_Ranklist: 'ACM Ranklist',
  mood: '소속',
  AC: 'AC',
  Rating: 'Rating',
  // Announcements.vue
  Contest_Announcements: 'Contest Announcements',
  By: 'By',
  // ApplyResetPassword.vue
  The_email_doesnt_exist: '해당 Email은 가입된 적이 없습니다.',
  Success: 'Success',
  Password_reset_mail_sent: '비밀번호를 전송하였습니다. Email을 확인 해주세요.',
  // FAQ.vue
  Frequently_Asked_Questions: '자주 물어보는 질문들',
  Where_is_the_input_and_the_output: 'Where is the input and the output?',
  Where_is_the_input_and_the_output_answer_part_1: 'Your program shall read input from',
  Standard_Input: 'Standard Input',
  Where_is_the_input_and_the_output_answer_part_3: 'and write output to',
  Standard_Output: 'Standard Output',
  Where_is_the_input_and_the_output_answer_part_5: 'For example,you can use',
  Where_is_the_input_and_the_output_answer_part_6: 'in C or',
  Where_is_the_input_and_the_output_answer_part_7: 'in C++ to read from stdin,and use',
  Where_is_the_input_and_the_output_answer_part_8: 'in C or',
  Where_is_the_input_and_the_output_answer_part_9: 'in C++ to write to stdout.  User programs are not allowed to read or write files, or you will get a',
  What_is_the_meaning_of_submission_execution_time: 'What\'s the meaning of the submission execution time?',
  What_is_the_meaning_of_submission_execution_time_answer: 'The onlinejudge might test your code multiple times with different input files. If your code gives the correct answer within the time limit for each input file, the execution time displayed is the max of the time spent for each test case. Otherwise, the execution time will have no sense.',
  How_Can_I_use_CPP_Int64: 'How can I use C++ Int64?',
  How_Can_I_use_CPP_Int64_answer_part_1: 'You should declare as',
  How_Can_I_use_CPP_Int64_answer_part_2: 'and use with',
  or: 'or',
  using: 'using',
  How_Can_I_use_CPP_Int64_answer_part_3: 'will result in',
  Java_specifications: 'Java specifications?',
  Java_specifications_answer_part_1: 'All programs must begin in a static main method in a',
  Java_specifications_answer_part_2: 'class. Do not use public classes: even',
  Java_specifications_answer_part_3: 'must be non public to avoid compile error.Use buffered I/O to avoid time limit exceeded due to excesive flushing.',
  About_presentation_error: 'About presentation error?',
  About_presentation_error_answer_part_1: 'There is no presentation error in this oj.The judger will trim the blacks and wraps in your ouput\'s',
  last: 'last',
  About_presentation_error_answer_part_2: 'line.  if it\'s still different with the correct output, the result will be',
  How_to_report_bugs: 'How to report bugs about this oj?',
  How_to_report_bugs_answer_part_1: 'The onlinejudge is open source, you can open an issue in',
  How_to_report_bugs_answer_part_2: 'The details(like env, version..) about a bug is required, which will help us a lot to solve the bug. Certainly, we are very pleased to merge your pull requests.',
  // Cancel.vue
  Cancel: 'Cancel',
  // ContestDetail.vue
  Problems: 'Problems',
  Announcements: '공지사항',
  Submissions: '채점 결과',
  Rankings: '랭킹',
  Overview: '개요',
  Admin_Helper: '관리자 Helper',
  StartAt: '시작일',
  EndAt: '종료일',
  ContestType: '권한',
  Creator: '제작자',
  Password_Protected: 'Password Protected',
  // ContestList.vue
  Rule: 'Rule',
  OI: 'OI',
  ACM: 'ACM',
  Underway: 'Underway',
  Not_Started: 'Not_Started',
  Ended: 'Ended',
  No_contest: '로그인 필요',
  Please_login_first: '로그인이 필요합니다.',
  // ContestProblemList
  Problems_List: '문제 목록',
  No_Problems: '문제가 존재하지 않습니다.',
  // CodeMirror.vue
  Language: 'Language',
  Theme: 'Theme',
  Reset_to_default_code_definition: 'Reset to default code definition',
  Upload_file: 'Upload file',
  Monokai: 'Monokai',
  Solarized_Light: 'Solarized Light',
  Material: 'Material',
  // KatexEditor.vue
  Latex_Editor: 'Latex Editor',
  // NavBar.vue
  Home: 'Home',
  Category: '문제리스트',
  NavProblems: '문제은행',
  Contests: '집합교육',
  NavStatus: '채점결과',
  Rank: '랭킹',
  ACM_Rank: 'ACM 랭킹',
  OI_Rank: 'OI 랭킹',
  About: '읽어보세요',
  G1_name: '[G1] 시작반 1',
  G2_name: '[G2] 시작반 2',
  G3_name: '[G3] 훈련반 1',
  G4_name: '[G4] 훈련반 2',
  G5_name: '[G5] 심화반 1',
  G6_name: '[G6] 심화반 2',
  Judger: '채점 시스템 정보',
  FAQ: '소스코드 제출방법',
  Login: '로그인',
  Register: '회원가입',
  MyHome: 'My홈',
  MySubmissions: 'My채점결과',
  Settings: '내 정보수정',
  Management: '관리자페이지',
  Logout: '로그아웃',
  Welcome_to: 'Welcome to',
  // announcements.vue
  Refresh: '새로고침',
  Back: 'Back',
  No_Announcements: '비어있음',
  // Setting.vue
  Profile: '내 정보수정',
  Account: '비밀번호 변경',
  Security: '보안 설정',
  // AccoutSetting.vue
  ChangePassword: '비밀번호 변경',
  ChangeEmail: 'Email 변경',
  Update_Password: '비밀번호 변경',
  // ProfileSetting.vue
  Avatar_Setting: '프로필 사진 등록',
  Profile_Setting: '내 정보수정',
  // SecuritySettig
  Sessions: '로그인 기록',
  Two_Factor_Authentication: '2 Factor 인증',
  // Login.vue
  LoginUsername: '아이디',
  LoginPassword: '비밀번호',
  TFA_Code: 'Code from your TFA app',
  No_Account: '회원가입',
  Forget_Password: '비밀번호 찾기',
  UserLogin: 'Login',
  Welcome_back: '로그인에 성공하였습니다.',
  // OIRank.vue
  OI_Ranklist: 'OI Ranklist',
  // OIContestRank.vue
  Total_Score: 'Total Score',
  // ProblemList.vue
  Problem_List: 'Problem List',
  High: 'High',
  Mid: 'Mid',
  Low: 'Low',
  All: 'All',
  Reset: 'Reset',
  Pick_One: 'Pick one',
  Difficulty: 'Difficulty',
  Total: 'Total',
  AC_Rate: 'AC Rate',
  // Register.vue
  RegisterUsername: '아이디',
  Email_Address: 'Email 주소',
  real_name: '이름',
  RegisterPassword: '비밀번호',
  Password_Again: '비밀번호 확인',
  Captcha: '보안문자',
  UserRegister: '회원가입',
  Already_Registed: '로그인',
  The_username_already_exists: '아이디가 이미 존재합니다.',
  The_email_already_exists: 'Email 주소가 이미 존재합니다.',
  password_does_not_match: '비밀번호가 일치하지 않습니다.',
  Thanks_for_registering: '회원가입에 성공하였습니다.',
  // ResetPassword.vue and ApplyResetPassword.vue
  Reset_Password: '비밀번호 찾기',
  RPassword: 'Password',
  RPassword_Again: 'Password Again',
  RCaptcha: 'Captcha',
  ApplyEmail: 'Your Email Address',
  Send_Password_Reset_Email: '비밀번호 리셋',
  Your_password_has_been_reset: 'Your password has been reset.',
  // Save.vue
  Save: 'Save',

  Uploading_is_in_progress: 'Uploading is in progress, are you sure to leave this page?',
  // SubmissionDetails.vue
  Lang: 'Lang',
  Share: 'Share',
  UnShare: 'UnShare',
  Succeeded: 'Succeeded',
  Real_Time: 'Real Time',
  Signal: 'Signal',
  // SubmissionList.vue
  When: '제출일',
  ID: 'ID',
  Time: 'Time',
  Memory: 'Memory',
  Author: '',
  Option: 'Option',
  Mine: 'Mine',
  Search_Author: 'Search Author',
  Accepted: '정답',
  Time_Limit_Exceeded: 'Time Limit Exceeded',
  Memory_Limit_Exceeded: 'Memory Limit Exceeded',
  Runtime_Error: 'Runtime Error',
  System_Error: 'System Error',
  Pending: 'Pending',
  Partial_Accepted: 'Partial Accepted',
  Compile_Error: 'Compile Error',
  Rejudge: 'Rejudge',
  // UserHome.vue
  UserHomeSolved: 'Solved',
  UserHomeserSubmissions: 'Submissions',
  UserHomeScore: 'Score',
  List_Solved_Problems: 'List of solved problems',
  UserHomeIntro: 'The guy is so lazy that has not solved any problem yet.'

  // 한글판으로 임시저장
}
